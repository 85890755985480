import React from 'react';

import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

const ContactForm = props => (
  <StaticQuery
    query={graphql`
      query {
        ocean_image: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "ocean" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <HeaderWrapper>
        <BackgroundImage fluid={data.ocean_image.childImageSharp.fluid}>
          <Grid id='contactForm'>
          <Text>
              <h2>Get in contact</h2>
              <br />
              <p>
               If you have any questions about my designs or would like to discuss a bespoke commission, please get in touch with me.
              </p>
            </Text>
            <Form>
              <form
                method="post"
                action="https://getform.io/f/72c85fc0-88bc-4829-bef2-0e2e18a7ff04"
              >
                <Label>
                  <input type="email" placeholder="Email" name="email" />
                </Label>
                <Label>
                  <input type="text" placeholder="Name" name="name" />
                </Label>
                <Label>
                  <textarea placeholder="Message" name="message" />
                </Label>
                <SendButton type="submit">Send</SendButton>
              </form>
            </Form>
          </Grid>
        </BackgroundImage>
      </HeaderWrapper>
    )}
  />
);

const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.color.primary};

  @media (max-width: ${props => props.theme.screen.md}) {
    // padding-top: 128px;
  }
`;

const SendButton = styled.button`
    margin-top: 30px;
    padding: 50px;
  &:hover {
    cursor: pointer;
  }

background-color: #183649;
display:inline-block;
 padding:0.7em 2em;
 margin: 0.3em 1em 0;
 border-radius:0.2em;
font-size: 20px;
 box-sizing: border-box;
 text-decoration:none;
 color:#FFFFFF;

 box-shadow:inset 0 -0.6em 1em -0.35em rgba(0,0,0,0.17),inset 0 0.6em 2em -0.3em rgba(255,255,255,0.15),inset 0 0 0em 0.05em rgba(255,255,255,0.12);
 text-align:center;
 position:relative;

:hover{
 box-shadow:inset 0 0.6em 2em -0.3em rgba(0,0,0,0.15),inset 0 0 0em 0.05em rgba(255,255,255,0.12);

`;

const Text = styled.div`
  align-items: center;
  font-size: 40px;
  color: ${props => props.theme.color.white.regular};
  text-shadow: 4px 4px 5px #183649;
  padding: 50px;
  margin: 0 100px;

  p{
    color: ${props => props.theme.color.white.regular};
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: start;
    margin: 0;
  }
`;

const Form = styled.div`
  align-items: center;
  ${props => props.theme.font_size.small};
  margin-bottom: 30px;

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: start;
    padding: 50px;
    width: 100%;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-gap: 60px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: right;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: center;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    & ${Form}{
      order: 2;
    }

    & ${Text}{
      order: 1;
    }
`;

const Label = styled.div`
  margin: 20px 0;
  width: 100%;
`;



export default ContactForm;
