import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Footer from '@common/Footer';

import ContactForm from '@sections/ContactForm';



const Contact = () => (
  <Layout>
    <Navbar />
    <ContactForm />
    <Footer />
  </Layout>
);

export default Contact;